import logo from './logo.svg';
import './App.css';
import datadog from './assets/1200px-Datadog_logo.svg.png'
import intuit from './assets/intuit-squarelogo-1573086631128.png'
import alula from './assets/alula-logo-for-geoarm-security-home.jpg';
import nasa from './assets/nasa.jpg';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


function App() {
  return (
    <Router>
    <div className="App">
      <div className="App-header">
        <h2 className="App-hTag App-name">Jose Gonzalez</h2>
        <h4 className="App-softwareEngineer App-hTag">Software Engineer</h4>
        <div>
          <nav className="App-navbar">
          <Link  className="App-link" to="/"><h5 className="App-hTag">Projects</h5></Link>
          <Link  className="App-link" to="/blog"><h5 className="App-hTag">Blog</h5></Link>
          <Link  className="App-link" to="/about"><h5 className="App-hTag">About me</h5></Link>
          <a target="_blank" className="App-link" href="https://github.com/JoseGonzalez212"><h5 className="App-hTag">Github</h5></a>
          <a target="_blank" className="App-link" href="https://www.linkedin.com/in/jose-gonzalez-05a597124/"><h5 className="App-hTag">LinkedIn</h5></a>
          </nav>
        </div>

        <Switch>
          <Route exact path="/about">
            <div className="App-About">
              <h6>I am a software engineer who loves learning new technologies.
              </h6>
              <h6>
                Front end: React, Redux, Apollo, and Typescript
              </h6>
              <h6>
                Back end: Express, Node, MongoDB, Java and Python
              </h6>
              <h6>
                I my free time enjoy reading, playing video and board games, and listening to music.
              </h6>
              <h6>
                Fun Facts:
              </h6>
              <h6>
                * My favourite band is The Beatles
              </h6>
              <h6>
                * Second favourite artist is Bob Dylan
              </h6>
              <h6>
                * I can solve a rubik's cube in under 1 miniute 30 seconds
              </h6>
            </div>
          </Route>
          <Route exact path="/">
            <div className="App-About-Items">
              <div className="App-Project-Item">
                <div className="App-Timeline-Item-TitleCompany-Details">
                  <h5 className="App-hTag">OfferDebater - Systematically compare you tech offers (Coming Soon)</h5>

                  <h6  className="App-hTag App-hTag-noBold">"That's a pretty cool idea." - anonymous</h6>
                  <h6  className="App-hTag App-hTag-noBold">"I love the emojis!" - anonymous</h6>
                  {/* add link to blog post */}
                </div>
              </div>
              <div className="App-Project-Item">
                <div className="App-Timeline-Item-TitleCompany-Details">
                  <h5 className="App-hTag">Spotify Playlist Creator (Working title) - Find the artists you both love</h5>
                </div>
              </div>
            </div>
          </Route>
          <Route exact path="/blog">
            <div className="blogPost">
              <h3>How to compare your tech offers</h3>
              <h5>
                (The problem)
                Software engineers have lots of options.
              </h5>
              <h6>
              Experienced interns who already have more than one internship under their belt can end up with many offers for their summer internships. New grads who have many internships and do well in coding interviews will have to decide on just one to take. An experienced engineer who does well in coding interviews can end up with many different offers. 
              </h6>
              <h6>
              Comparing different offers is very hard. There are a lot of criteria to consider. It’s hard to know if you will be making the right decision. 
              </h6>
              <h6>
              Because of that some resort to posting on a forum like Blind or Reddit to get opinions on their offers. The problem with this is that most people will judge these offers based on what they would take, not what the person should take based on what they want. 
              </h6>
              <h6>
              In order to simplify this problem we should approach this like engineers do!
We can more systematically compare offers with a framework to allow us to really see how each offer compares. 
              </h6>
              <h5>
              (Pre interviewing)
              </h5>
              <h6>
The first step to deciding what offer to accept actually starts before you even start interviewing. Before interviewing you should reflect on your past experiences. What things did you like? What things did you not like? What are you actually looking for in your next role?
              </h6>
              <h6>
              From this you can create a list of criteria. You should also rank which criteria is most important to you.  It's important to create a list of criteria you will use to compare your offers BEFORE you start interviewing. 
              </h6>
              <h6>
                Why?
              </h6>
              <h6>
              When you know what criteria you will be using you can then create questions to ask interviewers. 
During each interview you should ask a question or two to help determine how they stack up in that criteria. You should then take detailed notes about how each company answered these questions. Remember to probe and not to accept their first answer. Interviewers will want to only mention the good things about the company. (This is a good reason to also use glassdoor or blind to get a feel for how to company actually is) 
              </h6>
              <h6>
              This is also important because once you start interviewing you may lose sight of what’s important. You might get distracted by a company offering a much bigger salary than you expected. Or maybe it's a very big sign-on bonus.  
              </h6>
              <h6>
              You don’t want to change your criteria based on this. So before you start interviewing or get any offers, figure out what you want!
              </h6>
              <h5>
              (After receiving offers)
              </h5>
              <h6>
Once you receive your offers it's time to compare them. I would recommend using a decision matrix to do this. 
              </h6>
              <h6>
              A decision matrix first sets the criteria you will be using to decide on your offers. You then give each criteria a weight that shows how important each criteria is to you. You will then rate each company on this criteria assigning each a score. After this you can calculate each company's score by multiplying a company's rating by that criteria weight.
              </h6>
              <h6>
              You can use this google sheets template here: <a href="https://docs.google.com/spreadsheets/d/1f6N_P6CvoEvaCjE3yFRlAwisu_CT51K2wBrC2ubJ7wI/edit?usp=sharing">Compare Tech Offers</a>
              </h6>
              <h5>
              (Plug for OfferDebater)
              </h5>
              <h6>
                I had this problem as a new grad. I needed to decide between very different offers. I knew other people were also having this problem. So I created OfferDebater a completely free website you can use to compare your offers. 
                Systematically compare your offers here: https://offerdebater.herokuapp.com/
              </h6>
              <h6>
              So it's that simple right? Just plug your scores into that google sheet or my offer debater website, get your scores and then accept your new offer…
If only it was that simple. Doing all of this is actually just an exercise to see what you really want.  While I think these decision matrices are very good for making hard engineering decisions. They don’t take into account our general excitement for each offer. It's a good BS detector.

              </h6>
            </div>
          </Route>
        </Switch>
      </div>
    </div>
    </Router>
  );
}

export default App;
